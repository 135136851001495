import React from 'react';
import {cloneDeep as _cloneDeep} from 'lodash';
import IntlMessages from 'utils/IntlMessages';
import * as Permissions from 'constants/Permissions';
const [ isSuperAdmin, isCompanyAdmin, isTerminalAdmin, isShippingLineAdmin ] = [
  'isSuperAdmin',
  'isCompanyAdmin',
  'isTerminalAdmin',
  'isShippingLineAdmin'
];

export const selectedTerminalNav = (
  userType = [],
  showOnParam = [ '' ],
  showDashboard = true,
  marginLeft = ''
) => [
  {
    name: (
      <span className="dashboardBtn">
        <IntlMessages id={'sidebar.dashboard'} />
      </span>
    ),
    icon: <i className="zmdi zmdi-balance pr-2" />,
    type: 'item',
    link:
      '/companies/:selectedCompanyId/terminals/:selectedTerminalId/dashboard',
    allowedTo: {
      userType,
      showOnParam
    }
  },
  {
    name: <IntlMessages id={'sidebar.depotRecords'} />,
    icon: <i className="zmdi zmdi-railway pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType,
      showOnParam
    },
    marginLeft,
    children: [
      // {
      //   name: <IntlMessages id={'sidebar.cargo'} />,
      //   icon: <i className="zmdi zmdi-flight-land pr-2" />,
      //   type: 'item',
      //   link:
      //     '/companies/:selectedCompanyId/terminals/:selectedTerminalId/cargos',
      //   allowedTo: {
      //     userType,
      //     showOnParam
      //   }
      // },
      {
        name: <IntlMessages id={'sidebar.bankAccounts'} />,
        icon: <i className="zmdi zmdi-balance pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/bankAccounts',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.imageCategory'} />,
        icon: <i className="zmdi zmdi-group pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/image-categories',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.customers'} />,
        icon: <i className="zmdi zmdi-card-travel pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/customers',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.transporters'} />,
        icon: <i className="zmdi zmdi-truck pr-2" />,
        type: 'collapse',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/transporters',
        marginLeft: '20px',
        allowedTo: {
          userType,
          showOnParam
        },
        children: [
          {
            name: <IntlMessages id={'sidebar.trucks'} />,
            icon: <i className="zmdi zmdi-truck pr-2" />,
            type: 'item',
            link:
              '/companies/:selectedCompanyId/terminals/:selectedTerminalId/trucks',
            allowedTo: {
              userType,
              showOnParam
            }
          },
          {
            name: <IntlMessages id={'sidebar.drivers'} />,
            icon: <i className="zmdi zmdi-male-alt pr-2 ml-2" />,
            type: 'item',
            link:
              '/companies/:selectedCompanyId/terminals/:selectedTerminalId/drivers',
            allowedTo: {
              userType,
              showOnParam
            }
          },
          {
            name: <IntlMessages id={'sidebar.bulkUploads'} />,
            icon: <i className="zmdi zmdi-male-alt pr-2 ml-2" />,
            type: 'item',
            link:
              '/companies/:selectedCompanyId/terminals/:selectedTerminalId/bulkTruckDrivers',
            allowedTo: {
              userType: [ isSuperAdmin ]
            }
          }
        ]
      },
      {
        name: <IntlMessages id={'sidebar.container'} />,
        icon: <i className="zmdi zmdi-railway pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/containers',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.socContainer'} />,
        icon: <i className="zmdi zmdi-railway pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/soc-containers',
        allowedTo: {
          userType,
          showOnParam
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.images'} />,
    icon: <i className="zmdi zmdi-camera pr-2" />,
    type: 'item',
    marginLeft: marginLeft || '20px',
    link: '/companies/:selectedCompanyId/terminals/:selectedTerminalId/images',
    allowedTo: {
      userType,
      showOnParam
    }
  },
  {
    name: <IntlMessages id={'sidebar.serviceRecord'} />,
    icon: <i className="zmdi zmdi-filter-list pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType,
      showOnParam
    },
    marginLeft,
    children: [
      {
        name: <IntlMessages id={'sidebar.generalServices'} />,
        icon: <i className="zmdi zmdi-grain pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/services',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.repairServices'} />,
        icon: <i className="zmdi zmdi-plaster pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/repairServices',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.storageServices'} />,
        icon: <i className="zmdi zmdi-store pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/storageServices',
        allowedTo: {
          userType,
          showOnParam
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.workShop'} />,
    icon: <i className="zmdi zmdi-developer-board pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType,
      showOnParam
    },
    marginLeft,
    children: [
      {
        name: <IntlMessages id={'sidebar.repairEstimate'} />,
        icon: <i className="zmdi zmdi-assignment-o pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/repair-estimate',
        allowedTo: {
          userType,
          showOnParam,
          permissions: [ Permissions.CAN_ESTIMATE ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.serviceManagement'} />,
        icon: <i className="zmdi zmdi-redo pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/service-management',
        allowedTo: {
          userType,
          showOnParam,
          permissions: [ Permissions.CAN_START_SERVICE ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.additionalServices'} />,
    icon: <i className="zmdi zmdi-collection-plus pr-2" />,
    type: 'item',
    link:
      '/companies/:selectedCompanyId/terminals/:selectedTerminalId/additionalServices',
    marginLeft: marginLeft || '20px',
    allowedTo: {
      userType,
      showOnParam
    }
  },
  {
    name: <IntlMessages id={'sidebar.gateInOut'} />,
    icon: <i className="zmdi zmdi-swap pr-2" />,
    type: 'collapse',
    marginLeft,
    allowedTo: {
      userType,
      showOnParam
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.eirin'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/EIR-IN',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.cro'} />,
        icon: <i className="zmdi zmdi-plus-circle-o pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/cros',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.pickupSlip'} />,
        icon: <i className="zmdi zmdi-print pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/pickup-slip',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.eirOut'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/EIR-Out',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'unallocated.tanks'} />,
        icon: <i className="zmdi zmdi-badge-check pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/unallocated-tanks',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleIn'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/vehicle-in',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehiclePickupSlip'} />,
        icon: <i className="zmdi zmdi-print pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/vehicle-pickup-slip',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleOut'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/vehicle-out',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleOutsideOut'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/vehicle-outside-out',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.saleUnit'} />,
        icon: <i className="zmdi zmdi-balance-wallet pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/sale-unit',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.changeGradePortal'} />,
        icon: <i className="zmdi zmdi-flip pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/change-grade-portal',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.markScrap'} />,
        icon: <i className="zmdi zmdi-device-hub pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/mark-scrap',
        allowedTo: {
          userType,
          showOnParam
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.invoicing'} />,
    icon: <i className="zmdi zmdi-money pr-2" />,
    type: 'collapse',
    marginLeft,
    allowedTo: {
      userType,
      showOnParam
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.generateInvoice'} />,
        icon: <i className="zmdi zmdi-file-text pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/invoicing/generate-invoice',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'invoicePaid.paid'} />,
        icon: <i className="zmdi zmdi-money-box pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/invoicing/make-payment',
        allowedTo: {
          userType,
          showOnParam
        }
      }
      // {
      //   name: <IntlMessages id={'sidebar.outstandingReport'} />,
      //   icon: <i className="zmdi zmdi-file-text pr-2" />,
      //   type: 'item',
      //   link:
      //     '/companies/:selectedCompanyId/terminals/:selectedTerminalId/invoicing/outstanding-report',
      //   allowedTo: {
      //     userType,
      //     showOnParam
      //   }
      // }
    ]
  },
  {
    name: <IntlMessages id={'fasah'} />,
    icon: <i className="zmdi zmdi-file pr-2" />,
    type: 'collapse',
    marginLeft,
    allowedTo: {
      userType,
      showOnParam
    },
    children: [
      {
        name: <IntlMessages id={'fasah.inAppointments'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/in-appointments',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'fasah.outAppointments'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/out-appointments',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'fasah.bulkAppointments'} />,
        icon: <i className="zmdi zmdi-file-text pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/bulk-appointments',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'fasah.bulkDownloads'} />,
        icon: <i className="zmdi zmdi-file-text pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/bulk-downloads',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'fasah.payments'} />,
        icon: <i className="zmdi zmdi-money pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/payments',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'fasah.bulkPayments'} />,
        icon: <i className="zmdi zmdi-money pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/bulk-payments',
        allowedTo: {
          userType,
          showOnParam
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.reports'} />,
    icon: <i className="zmdi zmdi-collection-item pr-2" />,
    type: 'collapse',
    marginLeft,
    allowedTo: {
      userType,
      showOnParam
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.summary'} />,
        icon: <i className="zmdi zmdi-grid pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/summary',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.onGround'} />,
        icon: <i className="zmdi zmdi-view-dashboard pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/on-ground-report',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.containerHistory'} />,
        icon: <i className="zmdi zmdi-view-stream pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/containerHistory',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.gateIn'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/gateIn',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.gateOut'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/gateOut',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleOut'} />,
        icon: <i className="zmdi zmdi-long-arrow-return pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/vehicle-out',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleIn'} />,
        icon: <i className="zmdi zmdi-long-arrow-right pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/vehicle-in',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.customerFasah'} />,
        icon: <i className="zmdi zmdi zmdi-nfc pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/customer-fasah',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.containerFasah'} />,
        icon: <i className="zmdi zmdi-view-array pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/container-fasah',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'reports.eir'} />,
        icon: <i className="zmdi zmdi-swap pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/eir-report',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.croReport'} />,
        icon: <i className="zmdi zmdi-collection-item pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/each-cro-report',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.eachCROStatus'} />,
        icon: <i className="zmdi zmdi-collection-item-1 pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/eachCROStatus',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.allCROStatus'} />,
        icon: <i className="zmdi zmdi-collection-image-o pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/allCROStatus',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.stockReport'} />,
        icon: <i className="zmdi zmdi-storage pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/stockReport',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'report.schedule'} />,
        icon: <i className="zmdi zmdi-calendar-check pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/email-schedule',
        allowedTo: {
          userType,
          showOnParam
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.ediFact'} />,
    icon: <i className="zmdi zmdi-format-indent-increase pr-2" />,
    type: 'collapse',
    marginLeft,
    allowedTo: {
      userType,
      showOnParam
    },
    children: [
      {
        name: <IntlMessages id={'edi.schedule'} />,
        icon: <i className="zmdi zmdi-calendar-check pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/email-schedule',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'edi.ediContainerReport'} />,
        icon: <i className="zmdi zmdi-card pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/containers-report',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'edi.ediReport'} />,
        icon: <i className="zmdi zmdi-email pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/emails-report',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'edi.generateEDI'} />,
        icon: <i className="zmdi zmdi-case-download pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/generate-edi',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EDI ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.userManagement'} />,
    icon: <i className="zmdi zmdi-accounts-outline pr-2" />,
    type: 'collapse',
    marginLeft,
    allowedTo: {
      userType,
      showOnParam
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.users'} />,
        icon: <i className="zmdi zmdi-accounts-add pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/staff-management/users',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'appModule.roles'} />,
        icon: <i className="zmdi zmdi-home pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/staff-management/roles',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'appModule.loginDetails'} />,
        icon: <i className="zmdi zmdi-sign-in pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/staff-management/login-tokens',
        allowedTo: {
          userType,
          showOnParam
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.bulkSettings'} />,
    icon: <i className="zmdi zmdi-settings pr-2" />,
    type: 'collapse',
    marginLeft,
    allowedTo: {
      userType,
      showOnParam
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.services'} />,
        icon: <i className="zmdi zmdi-network-setting pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/bulk-settings/services',
        allowedTo: {
          userType,
          showOnParam
        }
      },
      {
        name: <IntlMessages id={'sidebar.grades'} />,
        icon: <i className="zmdi zmdi-memory pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/bulk-settings/grades',
        allowedTo: {
          userType,
          showOnParam
        }
      }
    ]
  }
];

export const selectedCompanyMenu = (userTypes, showSL = true) => [
  {
    name: <IntlMessages id={'sidebar.shippingLines'} />,
    icon: <i className="zmdi zmdi-boat pr-2" />,
    type: 'item',
    link: '/companies/:selectedCompanyId/shipping-lines',
    allowedTo: {
      userType: (showSL && userTypes) || [ '' ],
      showOnParam: [ 'companyId' ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.containerGrading'} />,
    icon: <i className="zmdi zmdi-wrench pr-2" />,
    type: 'item',
    link: '/companies/:selectedCompanyId/containerGradings',
    allowedTo: {
      userType: userTypes,
      showOnParam: [ 'companyId' ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.exchangeRates'} />,
    icon: <i className="zmdi zmdi-money-box pr-2" />,
    type: 'item',
    link: '/companies/:selectedCompanyId/exchange-rates',
    allowedTo: {
      userType: userTypes,
      showOnParam: [ 'companyId' ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.depots'} />,
    icon: <i className="zmdi zmdi-store-24 pr-2" />,
    type: 'item',
    link: '/companies/:selectedCompanyId/terminals',
    allowedTo: {
      userType: [ isCompanyAdmin ],
      showOnParam: [ 'companyId' ]
    }
  },
  {
    name: <span>Depot Dashboard</span>,
    nameFromStore: 'selectedTerminal',
    type: 'collapse',
    link:
      '/companies/:selectedCompanyId/terminals/:selectedTerminalId/dashboard',
    allowedTo: {
      userType: [ isCompanyAdmin ],
      showOnParam: [ 'terminalId' ]
    },
    children: _cloneDeep(
      selectedTerminalNav([ isCompanyAdmin ], [ 'terminalId' ], false, '20px')
    )
  }
];

export const superAdminNav = [
  {
    name: <IntlMessages id={'sidebar.dashboard'} />,
    icon: <i className="zmdi zmdi-home pr-2" />,
    type: 'item',
    link: '/'
  },
  {
    name: <IntlMessages id={'sidebar.ISOCodes'} />,
    icon: <i className="zmdi zmdi-file pr-2" />,
    type: 'item',
    link: '/isoCodes',
    allowedTo: {
      userType: [ isSuperAdmin ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.companies'} />,
    icon: <i className="zmdi zmdi-city-alt pr-2" />,
    type: 'item',
    link: '/companies',
    allowedTo: {
      userType: [ isSuperAdmin ]
    }
  },
  {
    name: 'Company Dashboard',
    nameFromStore: 'selectedCompany',
    type: 'collapse',
    link: '/companies/:selectedCompanyId/dashboard',
    allowedTo: {
      userType: [ isSuperAdmin ],
      showOnParam: [ 'companyId' ]
    },
    children: _cloneDeep(selectedCompanyMenu([ isSuperAdmin ], true))
  },
  {
    name: <IntlMessages id={'sidebar.depots'} />,
    icon: <i className="zmdi zmdi-store-24 pr-2" />,
    type: 'item',
    link: '/companies/:selectedCompanyId/terminals',
    allowedTo: {
      userType: [ isSuperAdmin ],
      showOnParam: [ 'companyId' ]
    }
  },
  {
    name: <span>Depot Dashboard</span>,
    nameFromStore: 'selectedTerminal',
    type: 'collapse',
    link:
      '/companies/:selectedCompanyId/terminals/:selectedTerminalId/dashboard',
    allowedTo: {
      userType: [ isSuperAdmin ],
      showOnParam: [ 'terminalId' ]
    },
    children: _cloneDeep(
      selectedTerminalNav([ isSuperAdmin ], [ 'terminalId' ], false, '20px')
    )
  },
  ..._cloneDeep(selectedCompanyMenu([ isCompanyAdmin ], true)),
  ..._cloneDeep(
    selectedTerminalNav([ isTerminalAdmin ], [ 'terminalId' ], false)
  )
];

export const userNav = [
  {
    name: 'Dashboard',
    icon: <i className="zmdi zmdi-boat pr-2" />,
    type: 'item',
    link: '/'
  },
  {
    name: (
      <strong>
        <IntlMessages id={'sidebar.dashboard'} />
      </strong>
    ),
    icon: <i className="zmdi zmdi-balance pr-2" />,
    type: 'item',
    link:
      '/companies/:selectedCompanyId/terminals/:selectedTerminalId/dashboard',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        ...Permissions.dashboardView,
        ...Permissions.fasahDashboardView,
        ...Permissions.ediDashboardView
      ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.depotRecords'} />,
    icon: <i className="zmdi zmdi-railway pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        Permissions.CAN_VIEW_CARGO,
        Permissions.CAN_VIEW_IMAGE_CATEGORY,
        Permissions.CAN_VIEW_CUSTOMER,
        Permissions.CAN_VIEW_TRANSPORTER,
        Permissions.CAN_VIEW_CONTAINER
      ]
    },
    children: [
      // {
      //   name: <IntlMessages id={'sidebar.cargo'} />,
      //   icon: <i className="zmdi zmdi-flight-land pr-2" />,
      //   type: 'item',
      //   link:
      //     '/companies/:selectedCompanyId/terminals/:selectedTerminalId/cargos',
      //   allowedTo: {
      //     userType: [ 'userNav' ],
      //     permissions: [ Permissions.CAN_VIEW_CARGO ]
      //   }
      // },
      {
        name: <IntlMessages id={'sidebar.imageCategory'} />,
        icon: <i className="zmdi zmdi-group pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/image-categories',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_IMAGE_CATEGORY ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.customers'} />,
        icon: <i className="zmdi zmdi-card-travel pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/customers',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_CUSTOMER ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.transporters'} />,
        icon: <i className="zmdi zmdi-truck pr-2" />,
        type: 'collapse',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/transporters',
        marginLeft: '20px',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_TRANSPORTER ]
        },
        children: [
          {
            name: <IntlMessages id={'sidebar.trucks'} />,
            icon: <i className="zmdi zmdi-truck pr-2" />,
            type: 'item',
            link:
              '/companies/:selectedCompanyId/terminals/:selectedTerminalId/trucks',
            allowedTo: {
              userType: [ 'userNav' ],
              permissions: [ Permissions.CAN_VIEW_TRUCK ]
            }
          },
          {
            name: <IntlMessages id={'sidebar.drivers'} />,
            icon: <i className="zmdi zmdi-male-alt pr-2 ml-2" />,
            type: 'item',
            link:
              '/companies/:selectedCompanyId/terminals/:selectedTerminalId/drivers',
            allowedTo: {
              userType: [ 'userNav' ],
              permissions: [ Permissions.CAN_VIEW_DRIVER ]
            }
          }
        ]
      },
      {
        name: <IntlMessages id={'sidebar.container'} />,
        icon: <i className="zmdi zmdi-railway pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/containers',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_CONTAINER ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.socContainer'} />,
        icon: <i className="zmdi zmdi-railway pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/soc-containers',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_SOC_CONTAINERS ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.images'} />,
    icon: <i className="zmdi zmdi-camera pr-2" />,
    type: 'item',
    link: '/companies/:selectedCompanyId/terminals/:selectedTerminalId/images',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [ Permissions.CAN_VIEW_CONTAINER_IMAGES ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.serviceRecord'} />,
    icon: <i className="zmdi zmdi-filter-list pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        Permissions.CAN_VIEW_GENERAL_SERVICE,
        Permissions.CAN_VIEW_REPAIR_SERVICE,
        Permissions.CAN_VIEW_STORAGE_SERVICE
      ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.generalServices'} />,
        icon: <i className="zmdi zmdi-grain pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/services',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_GENERAL_SERVICE ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.repairServices'} />,
        icon: <i className="zmdi zmdi-plaster pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/repairServices',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_REPAIR_SERVICE ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.storageServices'} />,
        icon: <i className="zmdi zmdi-store pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/storageServices',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_STORAGE_SERVICE ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.workShop'} />,
    icon: <i className="zmdi zmdi-developer-board pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        Permissions.CAN_ESTIMATE,
        Permissions.CAN_VIEW_SERVICE_MANAGEMENT,
        Permissions.CAN_VIEW_REPAIR_ESTIMATE
      ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.repairEstimate'} />,
        icon: <i className="zmdi zmdi-assignment-o pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/repair-estimate',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [
            Permissions.CAN_ESTIMATE,
            Permissions.CAN_VIEW_REPAIR_ESTIMATE
          ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.serviceManagement'} />,
        icon: <i className="zmdi zmdi-redo pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/service-management',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_SERVICE_MANAGEMENT ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.additionalServices'} />,
    icon: <i className="zmdi zmdi-collection-plus pr-2" />,
    type: 'item',
    marginLeft: '20px',
    link:
      '/companies/:selectedCompanyId/terminals/:selectedTerminalId/additionalServices',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [ Permissions.CAN_VIEW_ADDITIONAL_SERVICE ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.gateInOut'} />,
    icon: <i className="zmdi zmdi-swap pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        Permissions.CAN_VIEW_EIRIN,
        Permissions.CAN_VIEW_VEHICLE_IN,
        Permissions.CAN_VIEW_VEHICLE_OUT,
        Permissions.CAN_VIEW_VEHICLE_PICKUP_SLIP,
        Permissions.CAN_VIEW_CRO,
        Permissions.CAN_VIEW_PICKUP_SLIP,
        Permissions.CAN_VIEW_EIROUT,
        Permissions.CAN_VIEW_SALE_UNIT,
        Permissions.CAN_CHANGE_GRADE
      ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.eirin'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/EIR-IN',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EIRIN ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.cro'} />,
        icon: <i className="zmdi zmdi-plus-circle-o pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/cros',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_CRO ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.pickupSlip'} />,
        icon: <i className="zmdi zmdi-print pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/pickup-slip',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_PICKUP_SLIP ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.eirOut'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/EIR-Out',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EIROUT ]
        }
      },
      {
        name: <IntlMessages id={'unallocated.tanks'} />,
        icon: <i className="zmdi zmdi-badge-check pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/unallocated-tanks',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_UNALLOCATED_TANKS ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleIn'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/vehicle-in',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_VEHICLE_IN ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehiclePickupSlip'} />,
        icon: <i className="zmdi zmdi-print pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/vehicle-pickup-slip',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_VEHICLE_PICKUP_SLIP ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleOut'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/vehicle-out',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_VEHICLE_OUT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.saleUnit'} />,
        icon: <i className="zmdi zmdi-balance-wallet pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/sale-unit',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_SALE_UNIT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.changeGradePortal'} />,
        icon: <i className="zmdi zmdi-flip pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/change-grade-portal',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_CHANGE_GRADE ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.markScrap'} />,
        icon: <i className="zmdi zmdi-device-hub pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/mark-scrap',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [
            Permissions.CAN_MARK_SCRAP,
            Permissions.CAN_REVERT_SCRAP
          ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.invoicing'} />,
    icon: <i className="zmdi zmdi-money pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        Permissions.CAN_VIEW_GENERATE_INVOICE,
        Permissions.CAN_VIEW_INVOICES
      ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.generateInvoice'} />,
        icon: <i className="zmdi zmdi-file-text pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/invoicing/generate-invoice',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_GENERATE_INVOICE ]
        }
      },
      {
        name: <IntlMessages id={'invoicePaid.paid'} />,
        icon: <i className="zmdi zmdi-money-box pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/invoicing/make-payment',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_INVOICES ]
        }
      }
      // {
      //   name: <IntlMessages id={'sidebar.outstandingReport'} />,
      //   icon: <i className="zmdi zmdi-file-text pr-2" />,
      //   type: 'item',
      //   link:
      //     '/companies/:selectedCompanyId/terminals/:selectedTerminalId/outstanding-report',
      //   allowedTo: {}
      // }
    ]
  },
  {
    name: <IntlMessages id={'fasah'} />,
    icon: <i className="zmdi zmdi-file pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        Permissions.CAN_VIEW_IN_APPOINTMENT,
        Permissions.CAN_VIEW_OUT_APPOINTMENT,
        Permissions.CAN_UPLOAD_BULK_APPOINTMENTS,
        Permissions.CAN_DOWNLOAD_BULK_FASAH_APPOINTMENTS,
        Permissions.CAN_VIEW_FASAH_PAYMENTS
      ]
    },
    children: [
      {
        name: <IntlMessages id={'fasah.inAppointments'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/in-appointments',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_IN_APPOINTMENT ]
        }
      },
      {
        name: <IntlMessages id={'fasah.outAppointments'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/out-appointments',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_OUT_APPOINTMENT ]
        }
      },
      {
        name: <IntlMessages id={'fasah.bulkAppointments'} />,
        icon: <i className="zmdi zmdi-file-text pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/bulk-appointments',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_UPLOAD_BULK_APPOINTMENTS ]
        }
      },
      {
        name: <IntlMessages id={'fasah.bulkDownloads'} />,
        icon: <i className="zmdi zmdi-file-text pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/bulk-downloads',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_DOWNLOAD_BULK_FASAH_APPOINTMENTS ]
        }
      },
      {
        name: <IntlMessages id={'fasah.payments'} />,
        icon: <i className="zmdi zmdi-money pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/payments',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_FASAH_PAYMENTS ]
        }
      },
      {
        name: <IntlMessages id={'fasah.bulkPayments'} />,
        icon: <i className="zmdi zmdi-money pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/fasah/bulk-payments',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_FASAH_PAYMENTS ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.reports'} />,
    icon: <i className="zmdi zmdi-collection-item pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [ ...Permissions.reportsView ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.summary'} />,
        icon: <i className="zmdi zmdi-grid pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/summary',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_SUMMARY_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.onGround'} />,
        icon: <i className="zmdi zmdi-view-dashboard pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/on-ground-report',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_ON_GROUND_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.containerHistory'} />,
        icon: <i className="zmdi zmdi-view-stream pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/containerHistory',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_CONTAINER_HISTORY_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.gateIn'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/gateIn',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_GATE_IN_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.gateOut'} />,
        icon: <i className="zmdi zmdi-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/gateOut',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_GATE_OUT_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleOut'} />,
        icon: <i className="zmdi zmdi-long-arrow-return pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/vehicle-out',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_VEHICLE_OUT_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.vehicleIn'} />,
        icon: <i className="zmdi zmdi-long-arrow-right pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/vehicle-in',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_VEHICLE_IN_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.customerFasah'} />,
        icon: <i className="zmdi zmdi zmdi-nfc pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/customer-fasah',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_CUSTOMER_FASAH_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'reports.tableHeader.containerFasah'} />,
        icon: <i className="zmdi zmdi-view-array pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/container-fasah',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_CONTAINER_FASAH_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'reports.eir'} />,
        icon: <i className="zmdi zmdi-swap pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/eir-report',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EIR_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.croReport'} />,
        icon: <i className="zmdi zmdi-collection-item pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/each-cro-report',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EACH_CRO_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.eachCROStatus'} />,
        icon: <i className="zmdi zmdi-collection-item-1 pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/eachCROStatus',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EACH_CRO_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.allCROStatus'} />,
        icon: (
          <i className="zmdi zmdi-collection-image-o
        
        pr-2" />
        ),
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/allCROStatus',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_ALL_CRO_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.stockReport'} />,
        icon: <i className="zmdi zmdi-storage pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/stockReport',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_STOCK_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'report.schedule'} />,
        icon: <i className="zmdi zmdi-calendar-check pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/email-schedule',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_REPORT_EMAIL_SCHEDULE ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.ediFact'} />,
    icon: <i className="zmdi zmdi-format-indent-increase pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [
        Permissions.CAN_VIEW_EMAIL_SCHEDULE,
        Permissions.CAN_VIEW_EDI_CONTAINER_REPORT,
        Permissions.CAN_VIEW_EDI_EMAIL_REPORT,
        Permissions.CAN_GENERATE_EDI
      ]
    },
    children: [
      {
        name: <IntlMessages id={'edi.schedule'} />,
        icon: <i className="zmdi zmdi-calendar-check pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/email-schedule',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EMAIL_SCHEDULE ]
        }
      },
      {
        name: <IntlMessages id={'edi.ediContainerReport'} />,
        icon: <i className="zmdi zmdi-card pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/containers-report',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EDI_CONTAINER_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'edi.ediReport'} />,
        icon: <i className="zmdi zmdi-email pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/emails-report',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_VIEW_EDI_EMAIL_REPORT ]
        }
      },
      {
        name: <IntlMessages id={'edi.generateEDI'} />,
        icon: <i className="zmdi zmdi-case-download pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/edi/generate-edi',
        allowedTo: {
          userType: [ 'userNav' ],
          permissions: [ Permissions.CAN_GENERATE_EDI ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.userManagement'} />,
    icon: <i className="zmdi zmdi-accounts-outline pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [ ...Permissions.staffManagementView ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.users'} />,
        icon: <i className="zmdi zmdi-accounts-add pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/staff-management/users',
        allowedTo: {
          userType: [ 'terminalId' ],
          permissions: [ Permissions.CAN_VIEW_USER ]
        }
      },
      {
        name: <IntlMessages id={'appModule.roles'} />,
        icon: <i className="zmdi zmdi-home pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/staff-management/roles',
        allowedTo: {
          userType: [ 'terminalId' ],
          permissions: [ Permissions.CAN_VIEW_ROLE ]
        }
      },
      {
        name: <IntlMessages id={'appModule.loginDetails'} />,
        icon: <i className="zmdi zmdi-sign-in pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/staff-management/login-tokens',
        allowedTo: {
          userType: [ 'terminalId' ],
          permissions: [ Permissions.CAN_VIEW_LOGIN_TOKEN ]
        }
      }
    ]
  },
  {
    name: <IntlMessages id={'sidebar.bulkSettings'} />,
    icon: <i className="zmdi zmdi-network-setting pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ 'userNav' ],
      permissions: [ ...Permissions.bulkSettings ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.services'} />,
        icon: <i className="zmdi zmdi-memory pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/bulk-settings/services',
        allowedTo: {
          userType: [ 'terminalId' ],
          permissions: [ Permissions.CAN_ADD_UPDATE_BULK_SERVICES ]
        }
      },
      {
        name: <IntlMessages id={'sidebar.grades'} />,
        icon: <i className="zmdi zmdi-boat pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/bulk-settings/grades',
        allowedTo: {
          userType: [ 'terminalId' ],
          permissions: [ Permissions.CAN_ADD_UPDATE_BULK_GRADES ]
        }
      }
    ]
  }
];

export const shippingLineNav = [
  {
    name: 'Dashboard',
    icon: <i className="zmdi zmdi-home pr-2" />,
    type: 'item',
    link: '/',
    allowedTo: {
      userType: [ isShippingLineAdmin ],
      showOnParam: [ 'companyId' ]
    }
  },
  {
    name: <IntlMessages id={'sidebar.gateInOut'} />,
    icon: <i className="zmdi zmdi-swap pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ isShippingLineAdmin ],
      showOnParam: [ 'terminalId' ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.cro'} />,
        icon: <i className="zmdi zmdi-plus-circle-o pr-2" />,
        type: 'item',
        link: '/companies/:selectedCompanyId/terminals/:selectedTerminalId/cros'
      }
    ]
  },

  {
    name: <IntlMessages id={'sidebar.reports'} />,
    icon: <i className="zmdi zmdi-collection-item pr-2" />,
    type: 'collapse',
    allowedTo: {
      userType: [ isShippingLineAdmin ],
      showOnParam: [ 'terminalId' ]
    },
    children: [
      {
        name: <IntlMessages id={'sidebar.summary'} />,
        icon: <i className="zmdi zmdi-grid pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/summary'
      },
      {
        name: <IntlMessages id={'reports.tableHeader.gateIn'} />,
        icon: <i className="zmdi zmdi-arrow-left-bottom pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/gateIn'
      },
      {
        name: <IntlMessages id={'reports.tableHeader.gateOut'} />,
        icon: <i className="zmdi zmdi-arrow-right-top pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/gateOut'
      },
      {
        name: <IntlMessages id={'sidebar.croReport'} />,
        icon: <i className="zmdi zmdi-collection-item pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/each-cro-report'
      },
      {
        name: <IntlMessages id={'sidebar.eachCROStatus'} />,
        icon: <i className="zmdi zmdi-collection-item-1 pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/eachCROStatus'
      },
      {
        name: <IntlMessages id={'sidebar.allCROStatus'} />,
        icon: <i className="zmdi zmdi-collection-image-o pr-2" />,
        type: 'item',
        link:
          '/companies/:selectedCompanyId/terminals/:selectedTerminalId/reports/allCROStatus'
      }
    ]
  }
];
