export const SERVICE_TYPES = [
  {id: 2, name: 'DEFAULT'},
  {id: 1, name: 'ADDITIONAL'},
  {id: 3, name: 'NORMAL'}
];
export const CRO_OUT_TYPE = {
  RELEASE: 'Release',
  SHUTTLING: 'Shuttling'
};
export const READY = 'Ready';
export const GATED_IN = 'Gated In';
export const PENDING_ESTIMATE = 'Pending Estimate';
export const BLOCKED = 'Blocked';
export const GATED_OUT = 'Gated Out';
export const APPROVED_BY_LOGISTIC_MANAGER_ID = 2;
export const APPROVED = 1;
export const REQUESTED = 7;
export const REJECTED_ID = 6;
export const COMPLETED_SERVICE_ID = 4;
export const ON_GROUND = 'On Ground';
export const WAITING_FOR_ESTIMATE = 'Pending Estimate';
export const WAITING_FOR_TM = 'PA by TM';
export const WAITING_FOR_SL = 'PA by SL';
export const TRANSPORTER = 'Transporter';
export const CRO_EXPIRED = 'CRO EXPIRED';
export const CRO_NOT_FOUND = 'CRO NOT FOUND';
export const CRO_BLOCKED = 'CRO IS BLOCKED';
export const WAITING_FOR_APPROVAL = 'PA';
export const PENDING_APPROVAL = 'Pending Approval';
export const OF_TODAY_BTN = 'ofToday';
export const ON_TODAY_BTN = 'onToday';
export const AS_OF_SEVEN_DAYS = 'sDays';
export const AS_OF_THIRTY_DAYS = 'tDays';
export const REJECTED_STATUS = 'Rejected';
export const COMPLETED_STATUS = 'Completed';
export const APPROVED_SERVICE = 'Approved';
export const APPROVED_BY_LOGISTIC_MANAGER = 'Approved By Logistic Manager';
export const CANCELLED_WHILE_IN_PROCESS = 'Cancelled While In Process';
export const COMPLETED = 'Completed';
export const REJECTED = 'Rejected';
export const REQUESTED_SERVICE = 'Requested';
export const IN_PROCESS = 'In Process';
export const GATED_IN_ID = 0;
export const IN_PROCESS_ID = 0;
export const READY_ID = 1;
export const GATED_OUT_ID = 2;
export const WAITING_FOR_TM_ID = 3;
export const WAITING_FOR_SL_ID = 4;
export const WAITING_FOR_ESTIMATE_ID = 5;
export const ON_GROUND_ID = 6;
export const CRO_TYPE = 'CRO';
export const SHIPPING_LINE = 'Shipping Line';
export const CUSTOMER = 'Customer';
export const DEPOT_CUSTOMER = 'Depot Customer';
export const EIRIN_TYPE = 'EIRIN';
export const EIROUT_TYPE = 'EIROUT';
export const PICKUPSLIP_TYPE = 'PICKUPSLIP';
export const dummyISOCode = '0000';
export const all = 'all';
export const GATED_IN_CONTAINER = 'GatedIn Container';
export const IN_PROCESS_CONTAINER = 'InProcess Container';
export const READY_CONTAINER = 'Ready Container';
export const SHIPPING_LINE_PATH = 'shipping-lines';
export const CUSTOMER_PATH = 'customers';
export const TRANSPORTER_PATH = 'transporters';
export const DEPOT_CUSTOMER_PATH = 'depot-customers';
export const EMPTY_IMPORT = 'Empty_Import';
export const EMPTY_RETURN = 'Empty_Return';
export const FULL_IMPORT = 'Full_Import';
export const FULL_EXPORT = 'Full_Export';
export const CRO_BLOCKED_ID = 1;
export const CRO_COMPLETED_ID = 2;
export const CRO_EXPIRED_ID = 3;
export const CRO_ACTIVE_ID = 4;
export const CRO_BLOCKED_STATUS = 'Blocked';
export const CRO_COMPLETED_STATUS = 'Completed';
export const CRO_EXPIRED_STATUS = 'Expired';
export const CRO_ACTIVE_STATUS = 'Active';
export const GATE_IN = 'Gate In';
export const GATE_OUT = 'Gate Out';
export const SCRAP = 'Scrap';
export const ADDITIONAL = 'Additional';
export const PARTIAL_RELEASED = 'Partial Released';
export const ACTIVE_STATUS = 'Active';
export const INACTIVE_STATUS = 'In Active';
export const LAPSE = 'Lapse';
export const USED = 'Used';
export const USED_SLOT = 'Used Slot';
export const IN = 'IN';
export const OUT = 'OUT';
export const UNATTENDED = 'Unattended';
export const USED_U = 'Used-U';
export const dateFormat = [
  {name: 'DD-MMM-YY', value: 'DD-MMM-YY'},
  {name: 'MM-DD-YY', value: 'MM-DD-YY'}
];
export const BULK_FASAH_PRICE = [
  {label: 'SAR 30', value: 30},
  {label: 'SAR 20', value: 20}
];
